import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWallet, Endaoment } from "./hooks/wallet-request";
import { displayableAddress } from "./helpers";
import BlockExplorerLink from "./BlockExplorerLink";
import MetadataCard from "./MetadataCard";
import { formatBnUnits } from "./helpers";
import { ethers } from "ethers";
import ManagementCard from "./components/ManagementCard";
import { FormConfig } from "./components/ManagementCard/types";
import DropdownForm from "./components/DropdownForm";
import NumberEntryForm from "./components/NumberEntryForm";
import TextEntryForm from "./components/TextEntryForm";
import SingleButtonForm from "./components/SingleButtonForm";

const DisplayDuration = ({ duration }: { duration: ethers.BigNumber }) => {
  const months = duration.div(2629800);

  return <>{months.toString()} Month</>;
};

const Spinner = () => {
  return (
    <>
      <div className="animate-pulse mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <section aria-labelledby="core-info">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  <div className="h-2 bg-slate-700 rounded"></div>
                </h2>
                <p className="mt-1 text-sm text-gray-500"></p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      My Balance
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Beneficiary
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Endaoed Asset
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Total Assets
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Draw Amount
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Draw Periods
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Distributor Fee
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Claimable
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <div className="h-2 bg-slate-700 rounded"></div>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </div>
        <div>
          <section aria-labelledby="fund-card"></section>
        </div>
      </div>
    </>
  );
};

export default function EndaomentComponent() {
  const params = useParams();
  const {
    state,
    getEndaoment,
    addBenificiary,
    burn,
    claim,
    mint,
    epoch,
    removeBenificiary,
  } = useWallet();

  const fundingTabs = [
    {
      name: "Fund",
      component: NumberEntryForm,
      buttonAction: mint,
      requiresApprove: true,
      hasEntry: true,
      asset: state.data.endaoment?.asset?.address || "",
      buttonStates: [
        {
          name: "not-approved",
          bgColors: [
            "bg-indigo-600",
            "hover:bg-indigo-700",
            "focus:ring-indigo-500",
          ],
          buttonText: "Approve",
        },
        {
          name: "approved",
          bgColors: [
            "bg-green-600",
            "hover:bg-green-500",
            "focus:ring-green-500",
          ],
          buttonText: "Fund Endaoment",
        },
      ],
    },
    {
      name: "Withdraw",
      component: NumberEntryForm,
      buttonAction: burn,
      requiresApprove: false,
      hasEntry: true,
      asset: state.data.endaoment?.address || "",
      buttonStates: [
        {
          name: "approved",
          bgColors: [
            "bg-indigo-600",
            "hover:bg-indigo-700",
            "focus:ring-indigo-500",
          ],
          buttonText: "Withdraw",
        },
      ],
    },
    {
      name: "Distribute",
      component: DropdownForm,
      buttonAction: (targetBenificiary: string) => {
        epoch(endaoment.address, targetBenificiary);
      },
      options: state.data.endaoment?.beneficiaries || [],
      primary: "Which benificiary should receive the distribution?",
      buttonStates: [
        {
          name: "distribute",
          bgColors: [
            "bg-indigo-600",
            "hover:bg-indigo-700",
            "focus:ring-indigo-500",
          ],
          buttonText: "Distribute",
        },
      ],
    },
  ];

  const controlTabs: FormConfig[] = [
    {
      name: "Add Beneficiary",
      label: "Enter beneficiary address",
      placeholder: "0x....",
      component: TextEntryForm,
      buttonAction: addBenificiary,
      buttonStates: [
        {
          name: "addBenificiary",
          bgColors: [
            "bg-indigo-600",
            "hover:bg-indigo-700",
            "focus:ring-indigo-500",
          ],
          buttonText: "Add",
        },
      ],
    },
    {
      name: "Remove Beneficiary",
      label: "Select benificiary to remove",
      component: DropdownForm,
      buttonAction: (beneficiaryAddr: string) => {
        removeBenificiary(endaoment.address, beneficiaryAddr);
      },
      primary: "Which benificiary should be removed?",
      options: state.data.endaoment?.beneficiaries || [],
      buttonStates: [
        {
          name: "remove",
          bgColors: [
            "bg-indigo-600",
            "hover:bg-indigo-700",
            "focus:ring-indigo-500",
          ],
          buttonText: "Remove",
        },
      ],
    },
  ];

  if (state.data.endaoment?.claimable.amount.gt(0)) {
    controlTabs.push({
      name: "Claim",
      component: SingleButtonForm,
      buttonAction: claim,
      buttonStates: [
        {
          name: "claim",
          bgColors: [
            "bg-indigo-600",
            "hover:bg-indigo-700",
            "focus:ring-indigo-500",
          ],
          buttonText: "Claim",
        },
      ],
    });
  }

  useEffect(() => {
    if (
      !state.loading &&
      state.data.userAddress &&
      !state.data.endaoment &&
      params.endaomentID
    ) {
      getEndaoment(params.endaomentID, state.data.userAddress);
    }
  }, [
    state.loading,
    state.data.userAddress,
    getEndaoment,
    params.endaomentID,
    state.data.endaoment,
  ]);

  const endaoment = state.data.endaoment as Endaoment;

  let isBenificiary = false;
  if (endaoment?.beneficiaries && state.data.userAddress) {
    isBenificiary =
      endaoment.beneficiaries
        .map((ele) => ele.toLowerCase())
        .indexOf(state.data.userAddress.toLowerCase()) >= 0;
  }

  if (state.loading || !endaoment) {
    return <Spinner />;
  }

  const userBalance = state.data.endaomentUserBalance;

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl text-center font-semibold text-gray-900">
          Endaoment
        </h1>
        <BlockExplorerLink id={endaoment.address || ""}>
          <p className="text-center text-s text-gray-500">
            {endaoment.address}
          </p>
        </BlockExplorerLink>
      </div>
      <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <section aria-labelledby="core-info">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  {endaoment.name}
                </h2>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      My Balance
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {userBalance
                        ? formatBnUnits(
                            userBalance.amount,
                            userBalance.decimals
                          )
                        : "0"}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Beneficiaries
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <ul>
                        {endaoment.beneficiaries.map((ele) => {
                          return (
                            <li key={ele}>
                              <BlockExplorerLink id={ele}>
                                {displayableAddress(ele)}
                              </BlockExplorerLink>
                            </li>
                          );
                        })}
                      </ul>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Endaoed Asset
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <BlockExplorerLink
                        key={endaoment.asset?.address}
                        id={endaoment.asset?.address || ""}
                      >
                        {displayableAddress(endaoment.asset?.address || "")}
                      </BlockExplorerLink>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Total Assets
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatBnUnits(
                        endaoment.tlv.amount,
                        endaoment.tlv.decimals
                      )}{" "}
                      {endaoment.asset?.symbol}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Draw Amount
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatBnUnits(endaoment.epochDrawbips, 2)}
                      {"%"}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Draw Duration
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      <DisplayDuration duration={endaoment.epochDuration} />
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Distributor Fee
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {endaoment.fees
                        ? formatBnUnits(endaoment.fees.distributor, 2) + "%"
                        : ""}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Claimable
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatBnUnits(
                        endaoment.claimable.amount,
                        endaoment.claimable.decimals
                      )}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <MetadataCard metadataURI={endaoment.metadataURI} />
          </section>
        </div>
        <div>
          <section>
            <ManagementCard tabs={fundingTabs} endaoment={endaoment} />
          </section>
          {isBenificiary ? (
            <section className="mt-2">
              <ManagementCard tabs={controlTabs} endaoment={endaoment} />
            </section>
          ) : null}
        </div>
      </div>
    </>
  );
}
