import { useEffect, useReducer, useContext, useCallback } from "react";
import { ethers } from "ethers";
import FactoryAbi from "../abi/IEndaomentFactory.json";
import EndaomentAbi from "../abi/IEndaoment.json";
import ERC20Abi from "../abi/IERC20.json";
import AccessControlEnumerableAbi from "../abi/IAccessControlEnumerable.json";
import { WalletContext } from "./wallet";
import { Chain, supportedChains } from "./chains";
import log from "loglevel";
import Analytics from "../analytics";

const BENEFICIARY_ROLE = ethers.utils.keccak256(
  ethers.utils.toUtf8Bytes("BENEFICIARY_ROLE")
);

export interface Fees {
  distributor: ethers.BigNumber;
}

export interface Asset {
  name: string;
  symbol: string;
  address: string;
  decimals: number;
}

export interface Erc20Balance {
  amount: ethers.BigNumber;
  decimals: number;
}

export interface Allowance extends Asset {
  allowance: ethers.BigNumber;
  balance: ethers.BigNumber;
  decimals: number;
}

export interface Endaoment {
  address: string;
  name: string;
  tlv: Erc20Balance;
  claimable: Erc20Balance;
  decimals: number;
  epochDrawbips: ethers.BigNumber;
  epochDuration: ethers.BigNumber;
  asset?: Asset;
  beneficiaries: string[];
  metadataURI: string;
  totalSupply: ethers.BigNumber;
  fees: Fees;
}

interface Data {
  loading: boolean;
  data: {
    userAddress?: string;
    ethBalance?: ethers.BigNumber;
    endaoment?: Endaoment;
    endaoments?: string[];
    allowance?: Allowance;
    balance?: ethers.BigNumber;
    erc20Balance?: Erc20Balance;
    endaomentUserBalance?: Erc20Balance;
    chains?: Chain[];
    currentChain?: Chain;
  };
}

interface Action {
  type: string;
  payload?: any;
}

const reducer = (state: Data, action: Action): Data => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loading: true,
      };
    case "READY":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE":
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export const useWallet = () => {
  const { state } = useContext(WalletContext);
  const [myState, dispatch] = useReducer(reducer, {
    loading: true,
    data: {},
  });

  useEffect(() => {
    dispatch({ type: "LOADING", payload: {} });
    dispatch({ type: "UPDATE", payload: { userAddress: state.account } });
    dispatch({ type: "READY", payload: {} });
  }, [state.account]);

  useEffect(() => {
    dispatch({ type: "LOADING", payload: {} });
    dispatch({ type: "UPDATE", payload: { ethBalance: state.ethBalance } });
    dispatch({ type: "READY", payload: {} });
  }, [state.ethBalance]);

  useEffect(() => {
    const chains: Chain[] = [];
    let currentChain: Chain | undefined;
    supportedChains.forEach((val, key) => {
      const myChain = {
        ...val,
        selected: state.chain === val.id,
      };
      chains.push(myChain);

      if (state.chain === val.id && val.enabled) {
        currentChain = myChain;
      }
    });
    dispatch({ type: "UPDATE", payload: { currentChain, chains } });
  }, [state.chain]);

  const facoryAddress = myState.data.currentChain?.factoryContract || "";

  const changeChain = useCallback(
    async (chain: Chain) => {
      const hexid = "0x" + Number(chain.id).toString(16);
      await state.rpc.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: hexid }], // chainId must be in hexadecimal numbers
      });
    },
    [state.rpc]
  );

  const createErc20Endaoment = useCallback(
    async (
      endaomentName: string,
      symbol: string,
      epochDrawBips: ethers.BigNumber,
      epochDurationSecs: ethers.BigNumber,
      erc20AssetAddress: string,
      metadataURI: string = ""
    ) => {
      log.debug("Creating Endaoment");
      log.debug(
        endaomentName,
        symbol,
        epochDrawBips.toString(),
        epochDurationSecs.toString(),
        erc20AssetAddress,
        metadataURI
      );

      const contract = new ethers.Contract(
        facoryAddress,
        FactoryAbi,
        state.ethersProvider?.getSigner()
      );

      if (epochDrawBips.eq("0") || epochDurationSecs.eq("0")) {
        throw new Error("Invalid endaoment values");
      }

      const transaction = await contract.createErc20Endaoment(
        endaomentName,
        symbol,
        epochDrawBips,
        epochDurationSecs,
        erc20AssetAddress,
        metadataURI
      );

      log.debug(transaction);
      Analytics.track("Create Endaoment", {
        endaomentName,
        symbol,
        epochDrawBips: epochDrawBips.toString(),
        epochDurationSecs: epochDurationSecs.toString(),
        asset: erc20AssetAddress,
      });
      return transaction;
    },
    [state.ethersProvider, facoryAddress]
  );

  const _mergeProps = useCallback(
    async (props?: any): Promise<any> => {
      const currentBlock = await state.ethersProvider?.getBlock("latest");

      return {
        ...props,
        blockTag: currentBlock?.hash || "latest",
      };
    },
    [state.ethersProvider]
  );

  const _getBenificiaries = useCallback(
    async (endaomentAddress: string): Promise<string[]> => {
      log.debug("Getting beneficiaries for", endaomentAddress);
      let accessContract = new ethers.Contract(
        endaomentAddress,
        AccessControlEnumerableAbi,
        state.ethersProvider?.getSigner()
      );

      const props = _mergeProps();

      const out: string[] = [];
      const cnt = await accessContract.getRoleMemberCount(
        BENEFICIARY_ROLE,
        props
      );
      for (let i = 0; cnt.gt(i); i++) {
        const address = await accessContract.getRoleMember(
          BENEFICIARY_ROLE,
          i,
          props
        );
        out.push(address);
      }

      return out;
    },
    [state.ethersProvider, _mergeProps]
  );

  const _getErc20Balance = useCallback(
    async (asset: string, valueTarget: string): Promise<Erc20Balance> => {
      let erc20 = new ethers.Contract(
        asset,
        ERC20Abi,
        state.ethersProvider?.getSigner()
      );

      const amount = await erc20.balanceOf(valueTarget);
      const decimals = await erc20.decimals();

      return {
        amount,
        decimals,
      };
    },
    [state.ethersProvider]
  );

  const getErc20Balance = useCallback(
    async (asset: string, valueTarget: string): Promise<void> => {
      dispatch({ type: "LOADING" });

      const erc20Balance = await _getErc20Balance(asset, valueTarget);
      dispatch({ type: "UPDATE", payload: { erc20Balance } });
      dispatch({ type: "READY" });
    },
    [_getErc20Balance]
  );

  const getErc20Asset = async (address: string): Promise<void> => {
    dispatch({ type: "LOADING" });
    const asset = await _getErc20Asset(address);
    dispatch({ type: "UPDATE", payload: { asset } });
    dispatch({ type: "READY" });
  };

  const _getFees = useCallback(
    async (endaomentAddress: string): Promise<Fees> => {
      log.debug("Getting fees for", endaomentAddress);
      return {
        distributor: ethers.BigNumber.from("0"),
      };
    },
    []
  );

  const _getErc20Asset = useCallback(
    async (address: string): Promise<Asset | undefined> => {
      log.debug("Getting ERC20 Asset data");
      let erc20 = new ethers.Contract(
        address,
        ERC20Abi,
        state.ethersProvider?.getSigner()
      );

      const props = await _mergeProps();

      const symbol = await erc20.symbol(props);
      const name = await erc20.name(props);
      const decimals = await erc20.decimals(props);
      return {
        name,
        symbol,
        address,
        decimals,
      };
    },
    [state.ethersProvider, _mergeProps]
  );

  const _getEndaoment = useCallback(
    async (address: string): Promise<Endaoment | undefined> => {
      log.debug("Getting Endaoment", address);
      let endaoment = new ethers.Contract(
        address,
        EndaomentAbi,
        state.ethersProvider?.getSigner()
      );

      const contractEr20Props = await _getErc20Asset(address);
      const name = contractEr20Props?.name || "";

      const beneficiaries = await _getBenificiaries(address);
      const epochDrawBips = await endaoment.epochDrawBips();
      const epochDuration = await endaoment.epochDurationSecs();
      const assetAddress = await endaoment.asset();
      const metadataURI = await endaoment.metadataURI();
      const tlv = await _getErc20Balance(assetAddress, address); // HACK: should be calculating the value not the balance
      const asset = await _getErc20Asset(assetAddress);
      const claimable = await _getErc20Balance(address, address);
      const totalSupply = await endaoment.totalSupply();
      const fees = await _getFees(address);

      return {
        epochDrawbips: epochDrawBips,
        epochDuration: epochDuration,
        claimable: claimable,
        decimals: claimable.decimals,
        tlv: tlv,
        fees,
        totalSupply,
        asset,
        beneficiaries,
        address,
        name,
        metadataURI,
      };
    },
    [
      _getErc20Balance,
      _getErc20Asset,
      state.ethersProvider,
      _getBenificiaries,
      _getFees,
    ]
  );

  const getEndaoment = useCallback(
    async (endaomentAddress: string, userAddress: string): Promise<void> => {
      dispatch({ type: "LOADING" });
      const endaoment = await _getEndaoment(endaomentAddress);
      const endaomentUserBalance = await _getErc20Balance(
        endaomentAddress,
        userAddress
      );
      dispatch({
        type: "UPDATE",
        payload: { endaoment, endaomentUserBalance },
      });
      dispatch({ type: "READY" });
    },
    [_getErc20Balance, _getEndaoment]
  );

  const getEndaoments = useCallback(
    async (creatorId: string): Promise<void> => {
      const contract = new ethers.Contract(
        facoryAddress,
        FactoryAbi,
        state.ethersProvider?.getSigner()
      );
      const endaoments = await contract.getEndaomentsCreatedBy(creatorId);

      dispatch({ type: "UPDATE", payload: { endaoments } });

      dispatch({ type: "READY" });
    },
    [state.ethersProvider, facoryAddress]
  );

  const approve = async (
    assetAddress: string,
    endaomentAddress: string
  ): Promise<string> => {
    dispatch({ type: "LOADING" });
    log.debug("Approving", endaomentAddress, "spend of", assetAddress);
    let erc20 = new ethers.Contract(
      assetAddress,
      ERC20Abi,
      state.ethersProvider?.getSigner()
    );

    const tx = await erc20.approve(
      endaomentAddress,
      ethers.utils.parseEther("1000")
    );
    Analytics.track("Approve", { endaomentAddress, assetAddress });

    dispatch({ type: "READY" });
    return tx.hash;
  };

  const getAllowance = useCallback(
    async (
      assetAddress: string,
      owner: string,
      spender: string
    ): Promise<void> => {
      if (!state.ethersProvider) {
        return;
      }
      dispatch({ type: "LOADING" });
      log.debug(
        "Getting allowance of",
        spender,
        "spend of",
        assetAddress,
        "for",
        owner
      );
      let erc20 = new ethers.Contract(
        assetAddress,
        ERC20Abi,
        state.ethersProvider?.getSigner()
      );

      const myAllowance = await erc20.allowance(owner, spender);
      const myBalance = await erc20.balanceOf(owner);
      const decimals = await erc20.decimals();
      const symbol = await erc20.symbol();

      const allowance = {
        balance: myBalance,
        allowance: myAllowance,
        decimals,
        symbol,
      };

      dispatch({ type: "UPDATE", payload: { allowance } });

      dispatch({ type: "READY" });
    },
    [state.ethersProvider]
  );

  const getManagementInfo = useCallback(
    async (assetAddress: string, owner: string, spender: string) => {
      await getAllowance(assetAddress, owner, spender);
      await getErc20Balance(assetAddress, owner);
    },
    [getAllowance, getErc20Balance]
  );

  const removeBenificiary = async (
    endaomentAddress: string,
    target: string
  ): Promise<string> => {
    let endaoment = new ethers.Contract(
      endaomentAddress,
      EndaomentAbi,
      state.ethersProvider?.getSigner()
    );

    const tx = await endaoment.removeBenificiary(target);
    Analytics.track("Remove Beneficiary", { endaomentAddress });
    return tx.hash;
  };

  const addBenificiary = async (
    endaomentAddress: string,
    target: string
  ): Promise<string> => {
    let endaoment = new ethers.Contract(
      endaomentAddress,
      EndaomentAbi,
      state.ethersProvider?.getSigner()
    );

    const tx = await endaoment.addBenificiary(target);
    Analytics.track("Add Beneficiary", { endaomentAddress });
    return tx.hash;
  };

  const epoch = async (
    endaomentAddress: string,
    targetBenificiary: string
  ): Promise<string> => {
    let endaoment = new ethers.Contract(
      endaomentAddress,
      EndaomentAbi,
      state.ethersProvider?.getSigner()
    );
    const tx = await endaoment.epochAndDistribute(targetBenificiary);
    Analytics.track("EpochAndDistribute", {
      endaomentAddress,
      targetBenificiary,
    });
    return tx.hash;
  };

  const claim = async (endaomentAddress: string): Promise<string> => {
    let endaoment = new ethers.Contract(
      endaomentAddress,
      EndaomentAbi,
      state.ethersProvider?.getSigner()
    );

    const tx = await endaoment.distribute(state.account);
    Analytics.track("Distribute", { endaomentAddress });
    return tx.hash;
  };

  const burn = async (
    endaomentAddress: string,
    amount: string
  ): Promise<string> => {
    log.debug("Burn", amount, "for", endaomentAddress);
    const endaoment = new ethers.Contract(
      endaomentAddress,
      EndaomentAbi,
      state.ethersProvider?.getSigner()
    );

    const tx = await endaoment.burn(amount);
    Analytics.track("Burn", { endaomentAddress, amount });

    return tx.hash;
  };

  const mint = async (
    endaomentAddress: string,
    amount: string
  ): Promise<string> => {
    log.debug("Mint with", amount, "for", endaomentAddress);
    const endaoment = new ethers.Contract(
      endaomentAddress,
      EndaomentAbi,
      state.ethersProvider?.getSigner()
    );

    const tx = await endaoment.mint(amount);
    Analytics.track("Mint", { endaomentAddress, amount });

    return tx.hash;
  };

  return {
    // Props
    state: myState,
    // Functions
    getEndaoments,
    getEndaoment,
    getManagementInfo,
    createErc20Endaoment,
    mint,
    burn,
    approve,
    claim,
    epoch,
    getAllowance,
    getErc20Asset,
    getErc20Balance,
    changeChain,
    removeBenificiary,
    addBenificiary,
  };
};
