import React from "react";
import { Link } from "react-router-dom";

interface Props {
  href: string;
  className?: string;
  children?: React.ReactNode;
}

export default function EndaoLink({ href, className, children }: Props) {
  const isExternal = href.substring(0, 4) === "http";
  if (isExternal) {
    return (
      <a href={href} className={className}>
        {children}
      </a>
    );
  }
  // Internal Link
  return (
    <Link to={href} className={className}>
      {children}
    </Link>
  );
}
