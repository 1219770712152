import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useWallet, Endaoment } from "../hooks/wallet-request";
import { formatBnUnits } from "../helpers";
import MultiStateButton from "./MultiStateButton";
import { MultiStateButtonState } from "./ManagementCard/types";

export interface FormConfig {
  name: string;
  component: any; // TODO: need this type
  buttonAction: any; // TODO: need this type
  hasEntry: boolean;
  requiresApprove: boolean;
  asset?: string;
  buttonStates: MultiStateButtonState[];
}

const PercentageButton = ({
  percentage,
  onClick,
}: {
  percentage: number;
  onClick?: (event: React.MouseEvent) => void;
}) => {
  return (
    <span
      onClick={onClick}
      className="relative inline-flex items-center px-4 py-2 mx-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
    >
      {percentage}%
    </span>
  );
};

export default function NumberEntryForm({
  endaoment,
  formConfig,
}: {
  endaoment: Endaoment;
  formConfig: FormConfig;
}) {
  const [isApproved, setIsApproved] = useState(!formConfig.requiresApprove);
  const [inputValue, setInputValue] = useState("0");
  const { state, getAllowance, approve } = useWallet();

  useEffect(() => {
    if (
      formConfig.asset &&
      formConfig.asset !== "" &&
      state.data.userAddress &&
      endaoment.address
    ) {
      getAllowance(formConfig.asset, state.data.userAddress, endaoment.address);
    }
  }, [
    formConfig.asset,
    state.data.userAddress,
    endaoment.address,
    getAllowance,
  ]);

  useEffect(() => {
    if (state.data.allowance && state.data.allowance.allowance.gt(0)) {
      setIsApproved(true);
    }
  }, [state.data.allowance]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onButtonClick = async () => {
    if (formConfig.requiresApprove && !isApproved && endaoment.asset?.address) {
      await approve(endaoment.asset.address, endaoment.address);
      return;
    }
    // Approved
    const unitValue = ethers.utils.parseUnits(
      inputValue.toString(),
      endaoment.asset?.decimals
    );
    await formConfig.buttonAction(endaoment.address, unitValue.toString());
  };

  const onPercentClick = (perc: number) => {
    if (!state.data.allowance || !endaoment.asset) {
      return;
    }
    const percBn = ethers.BigNumber.from(perc);
    const res = state.data.allowance.balance.mul(percBn).div(100);
    setInputValue(formatBnUnits(res, endaoment.asset.decimals));
  };

  let buttonState = formConfig.buttonStates[0];

  if (formConfig.requiresApprove && isApproved) {
    buttonState = formConfig.buttonStates[1];
  }

  return (
    <div className="space-y-6">
      {formConfig.hasEntry ? (
        <>
          <div>
            <label
              htmlFor="amount"
              className="block text-sm font-medium text-gray-700"
            >
              Amount avaiable:{" "}
              {state.data.allowance
                ? formatBnUnits(
                    state.data.allowance.balance,
                    state.data.allowance.decimals
                  )
                : ""}{" "}
              {state.data.allowance?.symbol}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="number"
                name="price"
                id="price"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder="0.00"
                aria-describedby="price-currency"
                value={inputValue}
                onChange={onInputChange}
              />
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span className="text-gray-500 sm:text-sm" id="price-currency">
                  {state.data.allowance?.symbol}
                </span>
              </div>
            </div>
          </div>
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <PercentageButton
              percentage={25}
              onClick={() => onPercentClick(25)}
            />
            <PercentageButton
              percentage={50}
              onClick={() => onPercentClick(50)}
            />
            <PercentageButton
              percentage={75}
              onClick={() => onPercentClick(75)}
            />
            <PercentageButton
              percentage={100}
              onClick={() => onPercentClick(100)}
            />
          </span>
        </>
      ) : null}
      <MultiStateButton currentState={buttonState} onClick={onButtonClick} />
    </div>
  );
}
