export interface Chain {
  id: number;
  name: string;
  enabled: boolean;
  etherscanHost: string;
  factoryContract: string;
  selected?: boolean;
}

export const supportedChains: Map<number, Chain> = new Map([
  [
    1,
    {
      id: 1,
      name: "Ethereum",
      enabled: false,
      factoryContract: "",
      etherscanHost: "etherscan.io",
    },
  ],
  [
    3,
    {
      id: 3,
      name: "Ropsten",
      enabled: false,
      etherscanHost: "ropsten.etherscan.io",
      factoryContract: "",
    },
  ],
  [
    4,
    {
      id: 4,
      name: "Rinkeby",
      enabled: true,
      etherscanHost: "rinkeby.etherscan.io",
      factoryContract: "0x28e152EA16118549f4FC2df36eFaCC5A4aCf70BE",
    },
  ],
  [
    5,
    {
      id: 5,
      name: "Goerli",
      enabled: true,
      etherscanHost: "goerli.etherscan.io",
      factoryContract: "0x6f661C2c164214912657525fd665889b9BFEA724",
    },
  ],
  [
    42,
    {
      id: 42,
      name: "Kovan",
      enabled: false,
      etherscanHost: "kovan.etherscan.io",
      factoryContract: "",
    },
  ],
]);
