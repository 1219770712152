import { useMetadata } from "./hooks/useMetadata";
import { LinkIcon } from "@heroicons/react/solid";

export default function MetadataCard({
  metadataURI = "",
}: {
  metadataURI?: string;
}) {
  const metadata = useMetadata(metadataURI);

  if (!metadata) {
    return null;
  }

  return (
    <section aria-labelledby="metadata-card">
      <div className="bg-white shadow px-4 py-5 sm:rounded-lg">
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Description</dt>
          <dd className="mt-1 text-sm text-gray-900">{metadata.description}</dd>
        </div>
        <div className="sm:col-span-2">
          <dt className="text-sm font-medium text-gray-500">Links</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {metadata.links.map((link) => (
                <li
                  key={link.label}
                  className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                  <div className="w-0 flex-1 flex items-center">
                    <LinkIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      {link.label}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href={link.location}
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      View
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </dd>
        </div>
      </div>
    </section>
  );
}
