import { useEffect, useState } from "react";
import axios from "axios";

interface MetadataLink {
  label: string;
  location: string;
}
interface Metadata {
  addressIcon: string;
  description: string;
  links: MetadataLink[];
}

export const useMetadata = (metadataURI?: string) => {
  const [data, setData] = useState<Metadata | undefined>();

  useEffect(() => {
    if (metadataURI && metadataURI !== "") {
      const getData = async () => {
        const res = await axios.get(metadataURI);
        //TODO: add validation
        setData(res.data);
      };
      getData();
    }
  }, [metadataURI]);

  return data;
};
