import { useState } from "react";
import { useWallet } from "./hooks/wallet-request";
import { displayableAddress, formatBnUnits } from "./helpers";
import NetworkDrop from "./NetworkDrop";
import { SearchCircleIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";

const SearchBox = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`/endaoment/${inputValue}`);
  };

  return (
    <div className="w-3/5">
      <div className="mt-3 relative rounded-md shadow-sm">
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="search"
            id="search"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
            placeholder="Address"
            onChange={onInputChange}
          />
          <button type="submit" className="hidden" />
        </form>
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <SearchCircleIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </div>
  );
};

export default function TopNav() {
  const { state } = useWallet();

  const account = state.data.userAddress;

  const address = !account ? "" : account;

  const buttonText = state.data.ethBalance
    ? [
        displayableAddress(address),
        `(${formatBnUnits(state.data.ethBalance, 18)}`,
        "ETH)",
      ]
    : ["Connect Wallet"];

  return (
    <>
      <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
        <div className="flex-1 px-4 flex justify-between">
          <SearchBox />
          <div className="ml-4 flex items-center md:ml-6">
            <NetworkDrop />
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
            >
              {buttonText.join(" ")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
