import React from "react";

interface Props {
  primary: string;
  children?: React.ReactNode;
}
export default function PageHeader({ primary, children }: Props) {
  return (
    <div className="max-w-7xl mx-auto mb-8 px-4 sm:px-6 md:px-8">
      <h1 className="text-2xl text-center font-semibold text-gray-900">
        {primary}
      </h1>
      {children}
    </div>
  );
}
