import { useEffect, useMemo } from "react";
import { Fragment, useState } from "react";
import { Chain } from "./hooks/chains";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { classNames } from "./helpers";
import { useWallet } from "./hooks/wallet-request";
import Analytics from "./analytics";

export default function NetworkDrop() {
  const { state, changeChain } = useWallet();
  const [selected, setSelected] = useState<Chain | undefined>();
  const chainOptions = useMemo(() => {
    return state.data.chains || [];
  }, [state.data.chains]);

  useEffect(() => {
    for (let i = 0; i < chainOptions.length; i++) {
      const opt = chainOptions[i];
      if (opt.selected) {
        setSelected(opt);
      }
    }
  }, [chainOptions]);

  const filteredOpts = chainOptions.filter((chain) => {
    return chain.enabled || chain.selected;
  });

  const onChange = (newChain: Chain) => {
    setSelected(newChain);
    changeChain(newChain);
    Analytics.track("ChangeChain", { id: newChain.name });
  };

  if (!selected) {
    return null;
  }

  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="mr-4 relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="flex items-center">
                <span
                  aria-label={selected.enabled ? "Enabled" : "Disabled"}
                  className={classNames(
                    selected.enabled ? "bg-green-400" : "bg-gray-200",
                    "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                  )}
                />
                <span className="ml-3 block truncate">{selected.name}</span>
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {filteredOpts.map((myChain) => (
                  <Listbox.Option
                    key={myChain.id}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-indigo-600" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={myChain}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              myChain.enabled ? "bg-green-400" : "bg-gray-200",
                              "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                            )}
                            aria-hidden="true"
                          />
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3 block truncate"
                            )}
                          >
                            {myChain.name}
                            <span className="sr-only">
                              {" "}
                              is {myChain.enabled ? "enabled" : "disabled"}
                            </span>
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
