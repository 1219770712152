import * as React from "react";
import { useLocation } from "react-router-dom";
import { classNames } from "./helpers";
import EndaoLink from "./components/EndaoLink";
import FullMark from "./svg/full-mark-white.svg";

interface Item {
  name: string;
  href: string; // For Internal links
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  current: boolean;
}

interface SideNavProps {
  items: Item[];
}

interface LinkElementProps {
  item: Item;
}

const LinkElement = ({ item }: LinkElementProps) => {
  return (
    <EndaoLink
      href={item.href}
      className={classNames(
        item.current
          ? "bg-gray-900 text-white"
          : "text-gray-300 hover:bg-gray-700 hover:text-white",
        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
      )}
    >
      <item.icon
        className={classNames(
          item.current
            ? "text-gray-300"
            : "text-gray-400 group-hover:text-gray-300",
          "mr-3 flex-shrink-0 h-6 w-6"
        )}
        aria-hidden="true"
      />
      {item.name}
    </EndaoLink>
  );
};

export default function SideNav({ items }: SideNavProps) {
  const { pathname } = useLocation();
  const myItems = items.map((item) => {
    const current = item.href === pathname;
    return { ...item, current };
  });

  return (
    <>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex items-center h-16 flex-shrink-0 px-4 bg-gray-900">
            <EndaoLink href="/">
              <img className="h-8 w-auto" src={FullMark} alt="Endao" />
            </EndaoLink>
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 px-2 py-4 space-y-1">
              {myItems.map((item) => (
                <LinkElement item={item} key={item.name} />
              ))}
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
