import { Dispatch, useReducer, createContext, useEffect } from "react";
import { useMetaMask, MetaMaskProvider } from "metamask-react";
import { ethers } from "ethers";
import log from "loglevel";

export enum State {
  Init,
  Loading,
  Ready,
  Connected,
}

interface Data {
  state: State;
  account?: string;
  ethersProvider?: ethers.providers.Web3Provider;
  rpc?: any;
  chain?: number;
  ethBalance?: ethers.BigNumber;
}

interface Action {
  type: string;
  payload?: any;
}

const initData: Data = {
  state: State.Init,
};

const reducer = (state: Data, action: Action): Data => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        state: State.Loading,
      };
    case "READY":
      return {
        ...state,
        state: State.Ready,
      };
    case "CONNECTED":
      return {
        ...state,
        state: State.Connected,
      };
    case "SET_PROPS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

interface Ctxt {
  state: Data;
  dispatch: Dispatch<any>;
}

export const WalletContext = createContext<Ctxt>({
  state: initData,
  dispatch: () => null,
});

export const WalletProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const { status, connect, account, ethereum, chainId } = useMetaMask();
  const [state, dispatch] = useReducer(reducer, initData);

  useEffect(() => {
    connect();
  }, [chainId, connect]);

  useEffect(() => {
    const init = async () => {
      log.debug("Loading wallet connection");
      dispatch({
        type: "LOADING",
        payload: {},
      });

      const ethBalance = await ethereum.request({
        method: "eth_getBalance",
        params: [account, "latest"],
      });

      dispatch({
        type: "SET_PROPS",
        payload: { account, ethBalance },
      });

      dispatch({
        type: "CONNECTED",
      });

      log.debug("Wallet loaded");
    };

    if (
      status === "connected" &&
      state.state !== State.Loading &&
      !state.account &&
      !state.ethBalance
    ) {
      init();
    }
  }, [status, state.account, state.state, state.ethBalance, account, ethereum]);

  useEffect(() => {
    if (ethereum) {
      const ethersProvider = new ethers.providers.Web3Provider(ethereum);
      const rpc = ethereum;
      dispatch({
        type: "SET_PROPS",
        payload: { rpc, ethersProvider },
      });
    }
  }, [state.chain, ethereum]);

  useEffect(() => {
    const chain = Number(chainId);
    if (chain > 0 && chain !== state.chain) {
      log.debug("Set to chain", chain);
      dispatch({
        type: "SET_PROPS",
        payload: { chain },
      });
    }
  }, [chainId, state.chain]);

  return (
    <WalletContext.Provider value={{ state, dispatch }}>
      {children}
    </WalletContext.Provider>
  );
};

export const WrappedMetaMaskProvider = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <MetaMaskProvider>
      <WalletProvider>{children}</WalletProvider>
    </MetaMaskProvider>
  );
};
