import * as React from "react";
import { MultiStateButtonState } from "./ManagementCard/types";

export default function MultiStateButton({
  currentState,
  onClick,
}: {
  currentState: MultiStateButtonState;
  onClick: (event: React.MouseEvent) => void;
}) {
  const baseClx =
    "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2";

  const clx = [baseClx, ...currentState.bgColors].join(" ");

  return (
    <>
      <span className={clx} onClick={onClick}>
        {currentState.buttonText}
      </span>
    </>
  );
}
