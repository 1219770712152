import React from "react";
import { Link } from "react-router-dom";
import { useApi, CreatorsRes } from "./hooks/useApi";
import Loading from "./components/Loading";

interface Creator {
  name: string;
  address: string;
}

const CreatorRow = ({ creator }: { creator: Creator }) => {
  return (
    <>
      <tr key={creator.address}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <Link to={`/creator/${creator.address}`}>{creator.name}</Link>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <Link to={`/creator/${creator.address}`}>{creator.address}</Link>
        </td>
      </tr>
    </>
  );
};

export default function Creators() {
  const creators = useApi("/creators") as CreatorsRes[];

  if (!creators) {
    return <Loading />;
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl text-center font-semibold text-gray-900">
          Verified Endaoment Creators
        </h1>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Address
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {creators.map((creator, idx) => (
                    <CreatorRow key={idx} creator={creator} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
