import { useState, useEffect } from "react";
import { Endaoment } from "../hooks/wallet-request";
import { MultiStateButtonState } from "./ManagementCard/types";
import MultiStateButton from "./MultiStateButton";
import { Listbox } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";

export interface FormConfig {
  name: string;
  primary: string;
  component: any;
  buttonAction: any;
  buttonStates: MultiStateButtonState[];
  label: string;
  options: string[];
}

export default function DropdownForm({
  formConfig,
}: {
  endaoment: Endaoment;
  formConfig: FormConfig;
}) {
  const [inputValue, setInputValue] = useState("");

  const onButtonClick = async () => {
    await formConfig.buttonAction(inputValue.toString());
  };

  useEffect(() => {
    if (formConfig.options.length > 0) {
      setInputValue(formConfig.options[0]);
    }
  }, [formConfig.options]);

  let buttonState = formConfig.buttonStates[0];

  return (
    <div className="space-y-6">
      <span className="block text-sm font-medium text-gray-700">
        {formConfig.primary}
      </span>
      <Listbox value={inputValue} onChange={setInputValue}>
        {() => (
          <div className="relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="ml-3 block truncate">{inputValue}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {formConfig.options.map((addr) => (
                <Listbox.Option
                  key={addr}
                  value={addr}
                  className={
                    "cursor-default select-none relative py-2 pl-3 pr-9"
                  }
                >
                  {addr}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
      <MultiStateButton currentState={buttonState} onClick={onButtonClick} />
    </div>
  );
}
