import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useWallet } from "./hooks/wallet-request";
import { Link } from "react-router-dom";
import BlockExplorerLink from "./BlockExplorerLink";
import { formatBnUnits } from "./helpers";

const EndaomentItem = ({ address }: { address: string }) => {
  const { state, getEndaoment } = useWallet();

  useEffect(() => {
    if (address && state.data.userAddress) {
      getEndaoment(address, state.data.userAddress);
    }
  }, [address, state.data.userAddress, getEndaoment]);

  const generateLinkPath = (address: string) => {
    return ["/endaoment", address].join("/");
  };
  if (!state.data.endaoment || state.loading) {
    return (
      <>
        <tr className="animate-pulse" key={address}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <div className="h-2 bg-slate-700"></div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="h-2 bg-slate-700"></div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="h-2 bg-slate-700"></div>
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            <div className="h-2 bg-slate-700"></div>
          </td>
        </tr>
      </>
    );
  }

  const endaoment = state.data.endaoment;
  return (
    <>
      <tr key={address}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          <Link to={generateLinkPath(endaoment.address)}>{endaoment.name}</Link>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <Link to={generateLinkPath(endaoment.address)}>
            {endaoment.address}
          </Link>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {formatBnUnits(endaoment.tlv.amount, endaoment.tlv.decimals)}{" "}
          <BlockExplorerLink id={endaoment.asset?.address || ""}>
            {endaoment.asset?.symbol}
          </BlockExplorerLink>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {state.data.endaomentUserBalance
            ? formatBnUnits(
                state.data.endaomentUserBalance.amount,
                state.data.endaomentUserBalance.decimals
              )
            : "0"}
        </td>
      </tr>
    </>
  );
};

export default function EndaomentsList() {
  const { state, getEndaoments } = useWallet();
  const params = useParams();

  useEffect(() => {
    if (state.data.userAddress && params.creatorID && !state.data.endaoments) {
      getEndaoments(params.creatorID);
    }
  }, [
    state.data.endaoments,
    state.data.userAddress,
    params.creatorID,
    getEndaoments,
  ]);

  const endaoments = state.data.endaoments || [];

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl text-center font-semibold text-gray-900">
          Endaoments
        </h1>
        <BlockExplorerLink id={params.creatorID || ""}>
          <p className="text-center text-s text-gray-500">{params.creatorID}</p>
        </BlockExplorerLink>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Total Assets
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      My Balance
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {endaoments.map((address) => (
                    <EndaomentItem key={address} address={address} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
