import { useState } from "react";
import { Endaoment } from "../../hooks/wallet-request";
import { classNames } from "../../helpers";
import * as t from "./types";

export default function ManagementCard({
  endaoment,
  tabs,
}: {
  endaoment: Endaoment;
  tabs: t.FormConfig[];
}) {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const switchTab = (targetTab: string) => {
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i];
      if (tab.name === targetTab) {
        setCurrentTab(tab);
        return;
      }
    }
  };

  if (tabs.length === 0) {
    return null;
  }

  return (
    <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <span
                key={tab.name}
                className={classNames(
                  tab.name === currentTab.name
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "w-1/4 py-4 px-1 text-center border-b-2 font-medium text-sm"
                )}
                onClick={() => switchTab(tab.name)}
              >
                {tab.name}
              </span>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-6 flow-root">
        <currentTab.component endaoment={endaoment} formConfig={currentTab} />
      </div>
    </div>
  );
}
