import React from "react";
import { Routes, Route } from "react-router-dom";
import { HomeIcon, DocumentIcon, StarIcon } from "@heroicons/react/outline";
import TopNav from "./TopNav";
import SideNav from "./SideNav";
import EndaomentsList from "./EndaomentsList";
import Endaoment from "./Endaoment";
import Modal from "./Modal";
import { Helmet } from "react-helmet";
import { useWallet } from "./hooks/wallet-request";
import Creators from "./Creators";
import NewEndaoment from "./NewEndaoment";
import Actions from "./Actions";
import Featured from "./Featured";
import { supportedChains } from "./hooks/chains";

interface MainProps {
  children?: React.ReactNode;
}

const Main = ({ children }: MainProps) => {
  return (
    <main className="flex-1">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">{children}</div>
      </div>
    </main>
  );
};

export default function App() {
  const { state } = useWallet();

  const sideNav = [
    {
      name: "Home",
      href: "/",
      icon: HomeIcon,
      current: false,
    },
    {
      name: "Featured",
      href: "/featured",
      icon: StarIcon,
      current: false,
    },
    {
      name: "Documents",
      href: "https://docs.endao.finance",
      icon: DocumentIcon,
      current: false,
    },
  ];

  const avaiableNetworks: string[] = [];
  supportedChains.forEach((v, k) => {
    if (v.enabled) {
      avaiableNetworks.push(v.name);
    }
  });

  return (
    <>
      <Helmet>
        <title>Endao Protocol</title>
        <meta
          name="description"
          content="Fund projects or people that you love."
        />
        <meta itemProp="name" content="EnDAO" />
        <meta
          itemProp="description"
          content="Fund projects or people that you love."
        />
        <meta itemProp="image" content="" />

        <meta property="og:url" content="https://app.endao.finance" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="EnDAO" />
        <meta
          property="og:description"
          content="Fund projects or people that you love."
        />
        <meta property="og:image" content="" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="EnDAO" />
        <meta
          name="twitter:description"
          content="Fund projects or people that you love."
        />
        <meta name="twitter:image" content="" />
      </Helmet>
      <SideNav items={sideNav} />
      <div className="md:pl-64 flex flex-col">
        <TopNav />
        <Main>
          <Routes>
            <Route path="/" element={<Actions />} />
            <Route path="/featured" element={<Featured />} />
            <Route path="/creators" element={<Creators />} />
            <Route path="/creator/:creatorID" element={<EndaomentsList />} />
            <Route path="/endaoments/new" element={<NewEndaoment />} />
            <Route path="/endaoment/:endaomentID" element={<Endaoment />} />
          </Routes>
        </Main>
      </div>
      {!state.data.currentChain?.enabled ? (
        <Modal
          headline={"Wrong network"}
          subheading={`Interacting with Endao requires metamask. You either don't have metamask installed or currently you have selected a network not supported. Endao is deployed on the following networks: ${avaiableNetworks.join(
            ", "
          )}`}
          buttonText={"Ok"}
        />
      ) : null}
    </>
  );
}
