import { ethers } from "ethers";
import { supportedChains } from "./hooks/chains";

export const getNetworkName = (chainID: number): string => {
  return supportedChains.get(chainID)?.name || "Unknown Chain";
};

export const classNames = (...classes: any): string => {
  return classes.filter(Boolean).join(" ");
};

export const formatBnUnits = (
  inVal: ethers.BigNumber,
  units: number
): string => {
  return ethers.utils.formatUnits(inVal, units).substring(0, 6);
};

export const displayableAddress = (
  address: string,
  front: number = 6,
  back: number = 4
) => {
  return `${address.substring(0, front)}...${address.substring(
    address.length - back,
    address.length
  )}`;
};

export const calculateAnnualDraw = (
  epochDraw: ethers.BigNumber,
  epochDuration: ethers.BigNumber
): ethers.BigNumber => {
  const annaulSecs = ethers.BigNumber.from(31536000);
  const drawPeriodsPerYear = annaulSecs.div(epochDuration);
  return drawPeriodsPerYear.mul(epochDraw);
};
