import { useState } from "react";
import { Endaoment } from "../hooks/wallet-request";
import { MultiStateButtonState } from "./ManagementCard/types";
import MultiStateButton from "./MultiStateButton";

export interface FormConfig {
  name: string;
  component: any;
  buttonAction: any;
  buttonStates: MultiStateButtonState[];
  label: string;
  placeholder: string;
}

export default function SingleButtonForm({
  endaoment,
  formConfig,
}: {
  endaoment: Endaoment;
  formConfig: FormConfig;
}) {
  const [inputValue, setInputValue] = useState("");

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onButtonClick = async () => {
    await formConfig.buttonAction(endaoment.address, inputValue.toString());
  };

  let buttonState = formConfig.buttonStates[0];

  return (
    <div className="space-y-6">
      <div>
        <label
          htmlFor="amount"
          className="block text-sm font-medium text-gray-700"
        >
          {formConfig.label}
        </label>
        <div className="mt-1 relative rounded-md shadow-sm">
          <input
            type="text"
            name="price"
            id="price"
            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
            placeholder={formConfig.placeholder}
            aria-describedby="price-currency"
            value={inputValue}
            onChange={onInputChange}
          />
        </div>
      </div>
      <MultiStateButton currentState={buttonState} onClick={onButtonClick} />
    </div>
  );
}
