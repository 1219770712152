import { Endaoment } from "../hooks/wallet-request";
import MultiStateButton from "./MultiStateButton";
import { MultiStateButtonState } from "./ManagementCard/types";

export interface FormConfig {
  name: string;
  component: any; // TODO: need this type
  buttonAction: any; // TODO: need this type
  buttonStates: MultiStateButtonState[];
}

export default function SingleButtonForm({
  endaoment,
  formConfig,
}: {
  endaoment: Endaoment;
  formConfig: FormConfig;
}) {
  const onButtonClick = async () => {
    await formConfig.buttonAction(endaoment.address, "");
  };

  let buttonState = formConfig.buttonStates[0];

  return (
    <div className="space-y-6">
      <MultiStateButton currentState={buttonState} onClick={onButtonClick} />
    </div>
  );
}
