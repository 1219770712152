import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useWallet } from "./hooks/wallet-request";
import { useApi, FeaturedRes } from "./hooks/useApi";
import { displayableAddress, classNames } from "./helpers";
import PageHeader from "./components/PageHeader";

const FeaturedCard = ({ featured }: { featured: FeaturedRes }) => {
  const { state, getEndaoment } = useWallet();

  useEffect(() => {
    if (!state.loading && state.data.userAddress && !state.data.endaoment) {
      getEndaoment(featured.address, state.data.userAddress);
    }
  }, [
    state.data.userAddress,
    state.data.endaoment,
    state.loading,
    getEndaoment,
    featured.address,
  ]);

  const endaoment = state.data?.endaoment || undefined;

  if (!endaoment) {
    return (
      <li className="animate-pulse col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
        <div className="flex-1 flex flex-col p-8">
          <div className="mt-6 h-2 bg-slate-700"></div>
          <dl className="mt-1 flex-grow flex flex-col justify-between">
            <dt className="sr-only">Title</dt>
            <dd className="text-gray-500 text-sm">
              <div className="h-2 bg-slate-700"></div>
            </dd>
          </dl>
        </div>
      </li>
    );
  }
  let tagColor = "bg-gray-100";
  switch (featured.type) {
    case "nonprofit":
      tagColor = "bg-red-100";
      break;
    case "open-source":
      tagColor = "bg-green-100";
      break;
    case "creator":
      tagColor = "bg-blue-100";
      break;
  }
  return (
    <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
      <Link to={`/endaoment/${endaoment.address}`}>
        <div className="flex-1 flex flex-col p-8">
          <h3 className="mt-6 text-gray-900 text-sm font-medium">
            {endaoment.name}
          </h3>
          <dl className="mt-1 flex-grow flex flex-col justify-between">
            <dt className="sr-only">Title</dt>
            <dd className="text-gray-500 text-sm">
              {displayableAddress(endaoment.address)}
            </dd>
            <dt className="sr-only">Endaoment Type</dt>
            <dd className="mt-3">
              <span
                className={classNames(
                  "px-2 py-1 text-green-800 text-xs font-medium rounded-full",
                  tagColor
                )}
              >
                {featured.type}
              </span>
            </dd>{" "}
          </dl>
        </div>
      </Link>
    </li>
  );
};

export default function Featured() {
  const featured = useApi("/featured/endaoments") as FeaturedRes[];
  const { state } = useWallet();

  if (!featured) {
    return null;
  }

  const filtered = featured.filter((ele) => {
    return ele.chainID === state.data.currentChain?.id;
  });

  return (
    <>
      <PageHeader primary="Featured Endaoments" />
      <div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filtered.map((ele) => {
            return <FeaturedCard key={ele.address} featured={ele} />;
          })}
        </ul>
      </div>
    </>
  );
}
