import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import Axios from "axios";

const ApiHost = process.env.API_BASE || "https://api.endao.finance";

export interface CreatorsRes {
  name: string;
  address: string;
  chainId: number;
}

export interface AssetOption {
  label: string;
  address: string;
  chainID: number;
}

export interface DrawPeriodOption {
  label: string;
  value: BigNumber;
  drawAmountOptions: BigNumber[];
  chainID: number;
}

export interface NewFormOptsResponse {
  drawPeriodOptions: DrawPeriodOption[];
  assetOptions: AssetOption[];
}

export interface FeaturedRes {
  address: string;
  chainID: number;
  type: string;
  metadata?: {
    image: string;
  };
}

type Payload = CreatorsRes[] | NewFormOptsResponse | FeaturedRes[] | undefined;

interface Data {
  loading: boolean;
  payload?: Payload;
}

const serializeNewOpts = (rawJson: any): NewFormOptsResponse => {
  const drawPeriodOptions = rawJson.drawPeriodOptions.map((ele: any) => {
    return {
      label: ele.label,
      value: BigNumber.from(ele.seconds),
      chainID: ele.chainID,
      drawAmountOptions: ele.drawAmountOptions.map(BigNumber.from),
    };
  });

  return {
    assetOptions: rawJson.assetOptions,
    drawPeriodOptions,
  };
};

export const useApi = (path: string): Payload => {
  const [data, setData] = useState<Data>({
    loading: false,
    payload: undefined,
  });

  const baseUrl = ApiHost;

  useEffect(() => {
    // Set to loading
    setData({
      loading: true,
      payload: data.payload,
    });

    const getData = async () => {
      const fullUrl = baseUrl + path;
      var res;
      switch (path) {
        case "/featured/endaoments":
          res = await Axios.get(fullUrl);
          setData({
            payload: res.data as FeaturedRes[],
            loading: false,
          });
          break;
        case "/creators":
          res = await Axios.get(fullUrl);
          setData({
            payload: res.data as CreatorsRes[],
            loading: false,
          });
          break;
        case "/endaoment/new/options":
          res = await Axios.get(fullUrl);

          const payload = serializeNewOpts(res.data);

          setData({
            loading: false,
            payload,
          });
          break;
        default:
          break;
      }
    };

    if (!data.loading && !data.payload) {
      getData();
    }
  }, [path, baseUrl, data.loading, data.payload]);

  return data.payload;
};
