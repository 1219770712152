import React from "react";
import { useWallet } from "./hooks/wallet-request";
import EndaoLink from "./components/EndaoLink";

export enum IdType {
  Transaction = "tx",
  Address = "address",
}

interface Params {
  id: string;
  idType?: IdType;
  className?: string;
  children?: React.ReactNode;
}

export default function BlockExplorerLink({
  id,
  className,
  idType = IdType.Address,
  children,
}: Params) {
  const { state } = useWallet();
  let blockExplorerBase = "etherscan.io";
  const selectedChains =
    state.data.chains?.filter((chain) => {
      return chain.selected;
    }) || [];
  if (selectedChains.length > 0) {
    blockExplorerBase = selectedChains[0].etherscanHost;
  }
  let typePath = idType.toString();
  const fullHref = ["https://", blockExplorerBase, typePath, id];

  return (
    <EndaoLink href={fullHref.join("/")} className={className} key={id}>
      {children}
    </EndaoLink>
  );
}
