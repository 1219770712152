import { useState, useEffect } from "react";
import { useWallet } from "./hooks/wallet-request";
import { useNavigate } from "react-router-dom";
import { BigNumber } from "ethers";
import {
  useApi,
  NewFormOptsResponse,
  DrawPeriodOption,
  AssetOption,
} from "./hooks/useApi";

interface DrawPeriod {
  label: string;
  value: BigNumber;
  drawAmountOptions: BigNumber[];
}
interface FormData {
  name: string;
  symbol: string;
  drawBips: BigNumber;
  drawPeriod: DrawPeriod;
  asset: string;
}

const createSymbol = (endaomentName: string): string => {
  if (endaomentName === "") {
    return "";
  }

  const nameSplit = endaomentName.split(" ");
  let out = "e";

  nameSplit.forEach((ele) => {
    if (ele === "") {
      return;
    }
    out = out + ele[0].toUpperCase();
  });

  return out;
};
export default function NewEndaoment() {
  const formOpts = useApi("/endaoment/new/options") as NewFormOptsResponse;
  const { createErc20Endaoment, state } = useWallet();
  const [formData, setFormData] = useState<FormData | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    let initForm: FormData = {
      name: "",
      symbol: "",
      drawBips: BigNumber.from("500"),
      drawPeriod: {
        label: "Monthly",
        value: BigNumber.from("2629800"),
        drawAmountOptions: [],
      },
      asset: "",
    };
    if (formOpts) {
      initForm = {
        name: "",
        symbol: "",
        drawBips: formOpts.drawPeriodOptions[0].drawAmountOptions[0],
        drawPeriod: formOpts.drawPeriodOptions[0],
        asset: formOpts.assetOptions[0].address,
      };
    }
    setFormData(initForm);
  }, [formOpts]);

  let assetOptions: AssetOption[] = [];
  let drawPeriodOptions: DrawPeriodOption[] = [];

  if (formOpts) {
    // Filter to current chain
    assetOptions = formOpts.assetOptions?.filter((opt) => {
      return opt.chainID === state.data.currentChain?.id;
    });

    drawPeriodOptions = formOpts.drawPeriodOptions.filter((opt) => {
      return !opt.chainID || opt.chainID === state.data.currentChain?.id;
    });
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (formData) {
      await createErc20Endaoment(
        formData.name,
        formData.symbol,
        formData.drawBips, // Draw bips
        formData.drawPeriod.value, // duration secs
        formData.asset
      );
      navigate("/creator/" + state.data.userAddress);
      return;
    }
  };

  const drawPeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    if (!formData) {
      return;
    }
    for (let i = 0; i < formOpts.drawPeriodOptions.length; i++) {
      const opt = formOpts.drawPeriodOptions[i];
      if (opt.label === event.target.value) {
        setFormData({
          ...formData,
          drawPeriod: opt,
          drawBips: opt.drawAmountOptions[0],
        });
        return;
      }
    }
  };

  const onInputChange =
    (fieldName: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      event.preventDefault();
      let newData: any = {};

      if (fieldName === "drawSecs") {
        drawPeriodChange(event as React.ChangeEvent<HTMLSelectElement>);
        return;
      }
      var value: string | BigNumber = event.target.value;
      if (fieldName === "drawBips") {
        value = BigNumber.from(value);
      }
      if (fieldName === "name") {
        console.log(fieldName, value);
        newData.symbol = createSymbol(value as string);
      }
      newData[fieldName] = value;
      console.log(newData);
      setFormData({
        ...formData,
        ...newData,
      });
    };

  if (!formOpts) {
    return (
      <>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl text-center font-semibold text-gray-900">
            New Endaoment
          </h1>
        </div>
        <form
          className="space-y-8 divide-y divide-gray-200"
          onSubmit={onSubmit}
          autoComplete="off"
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div className="pt-8">
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Endaoment Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={onInputChange("name")}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="drawPeriod"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Draw Period
                  </label>
                  <div className="mt-1">
                    <input
                      id="drawPeriod"
                      name="drawPeriod"
                      type="text"
                      placeholder="Amount a draw can occur in seconds 2628000 = 1 month"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={onInputChange("drawSecs")}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Draw Amount
                  </label>
                  <div className="mt-1">
                    <input
                      id="drawAmount"
                      name="drawAmount"
                      type="text"
                      placeholder="Amount in basis points, ex 100 = 1%"
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={onInputChange("drawBips")}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="country"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Asset
                  </label>
                  <div className="mt-1">
                    <input
                      id="asset"
                      name="asset"
                      type="text"
                      placeholder="0x....."
                      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      onChange={onInputChange("asset")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </>
    );
  }

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl text-center font-semibold text-gray-900">
          New Endaoment
        </h1>
      </div>
      <form className="space-y-8 divide-y divide-gray-200" onSubmit={onSubmit}>
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="pt-8">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Endaoment Name
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="text"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={onInputChange("name")}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="drawPeriod"
                  className="block text-sm font-medium text-gray-700"
                >
                  Draw Period
                </label>
                <div className="mt-1">
                  <select
                    id="drawPeriod"
                    name="drawPeriod"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={onInputChange("drawSecs")}
                  >
                    {drawPeriodOptions.map((period, idx) => {
                      return (
                        <option key={idx} value={period.label}>
                          {period.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Draw Amount
                </label>
                <div className="mt-1">
                  <select
                    id="drawAmount"
                    name="drawAmount"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={onInputChange("drawBips")}
                  >
                    {formData
                      ? formData.drawPeriod.drawAmountOptions.map(
                          (myOpt, idx) => {
                            return (
                              <option key={idx} value={myOpt.toString()}>
                                {myOpt.toString()}
                                {" bips"}
                              </option>
                            );
                          }
                        )
                      : null}
                  </select>
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700"
                >
                  Asset
                </label>
                <div className="mt-1">
                  <select
                    id="asset"
                    name="asset"
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    onChange={onInputChange("asset")}
                  >
                    {assetOptions.map((opt, idx) => {
                      return (
                        <option key={idx} value={opt.address}>
                          {opt.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-5">
          <div className="flex justify-end">
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Create
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
